#sb-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: top 0 left 300;
  background-color:  #ea3b13 ;
	background-size: 100%;
  width: 87.5% !important;
	/* height: 100%; */
	font-size: 16px;
	font-weight: 500;
	height: 48px !important;
	border-radius: 10px; 
	text-align: center;
	color: whitesmoke;
	line-height: 20px;
	border: none;
	box-shadow: 5px 5px 15px rgb(143, 199, 227, 0.5);
}

#sb-button_hover {
  cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: top 0 left 300;
  background-image: radial-gradient( circle at center, #ea3b13, #e16845, #e99937 );
  background-size: 150%;
	background-color: #ea3b13; 
	background-blend-mode: normal;
	width: 87.5% !important;
	/* height: 100%; */
	font-weight: 500;
	font-size: 16px;
	height: 48px;
	border-radius: 10px; 
	text-align: center;
	color: whitesmoke;
	line-height: 20px;
	border: none;
}


@media (max-width: 768px) {
  #sb-button,
  #sb-button_hover {
    width: 90%;
    height: 40px;
    font-size: 14px;
  }
}