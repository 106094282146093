#nb-master {
    display: flex;justify-content: space-between;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: #f9e0c6;
    flex-shrink: 0;
    z-index: 1;
    border-bottom: 2px solid black;

}

#nb-logoPic {
    max-height: 45px;
    padding-left: 10px ;
}

#nb-rightLinks {
    display: flex;
    height: 100%;
    color: var(--black);
    font-size: 18px;
    font-weight: 500;
}

#nb-rightLinks div {
    width: auto;
    padding: 0 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

#nb-rightLinks a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

#nb-rightLinks div:hover {
    background-color: #ebebeb;
    color: whitesmoke;
    background-color: var(--orange);
}

#nb-rightLinks a:hover {
    background-color: #ebebeb;
    color: whitesmoke;
    background-color: var(--orange);
}