#upcoming {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 400px;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
}

#ug-title {
  position: sticky;
  top: 0;
  font-size: 25px;
  font-family: Jost, sans-serif;
  width: 100%;
  height: 50px !important;
  padding-top: 15px;
  padding-bottom: 15px;
    background-color: rgb(225, 104, 69, 0.5);
}

#ug-card-container {
  width: 80%;
}

#scroll-wrapper {
  overflow: hidden;
  border-radius: 10px;
  width: 47%;
  height: 100%;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: rgb(225, 104, 69, 0.5);
  box-shadow: 5px 5px 15px rgb(143, 199, 227, 0.5);
    font-size: 25px;
  font-family: Jost, sans-serif;
}
