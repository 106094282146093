body {
  font-family: 'Jost', sans-serif !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

:root {
  --orange: #ea3b13;
  --peach: #e16845;
  --yellow: #e99937;
  --white: #ffffff;
  --black: #000000;
  --gradient: linear-gradient(to left,
  #ea3b13 33%,
  #e16845 33%,
  #e99937 33%,
 );
 --shawdow: 5px 5px 15px rgb(143, 199, 227, 0.5);
 --modal-background: rgb(225, 104, 69, 0.5);
 --background: rgb(236, 59, 19, 0.3);
}

.errors {
  color: red
}