#team {
  display: flex;
  flex-wrap: wrap;
}
#ab {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
}

#description-container {
  border-radius: 10px;
  width: 75%;
  padding: 30px;
  background-color: rgb(225, 104, 69, 0.5);
  box-shadow: 5px 5px 15px rgb(143, 199, 227, 0.5);
  margin-top: 30px;
}

#description {
  font-family: Jost, sans-serif;
  font-size: 18px;
}

#team-container {
  border-radius: 10px;
  width: 75%;
  padding: 30px;
  font-family: Jost, sans-serif;
  background-color: rgb(225, 104, 69, 0.5);
  box-shadow: 5px 5px 15px rgb(143, 199, 227, 0.5);
}

#about {
  display: flex;
  margin: 0 auto;
}

#about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 60px;
  flex: 1;
  
}

#team-photo {
  width: 200px;
  height: 200px;
  border-radius: 50px;
}
#team {
  display: flex;
  justify-content: space-between;
}

#name-title {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
}

#icons {
  display: flex;
  justify-content: center;
}

#about-container {
  background-color:  rgb(236, 59, 19, 0.3);
}
