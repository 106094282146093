#up-information {
  margin: 40px;
  margin-top: 20px;
}

#up-email, #up-username, #up-name {
  width: 100%;
  font-family: Jost, sans-serif;
}

#up-email input, #up-username input, #up-name input {
  font-family: Jost, sans-serif;
  width: 100%;
  border-radius: 10px;
  border: 1px solid gray;
  line-height: 40px;
  font-size: 16px;
  background-color:whitesmoke;
}

#bio {
  width: 100%;
  font-family: Jost, sans-serif;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid gray;
  background-color:whitesmoke;
}

#dropdown {
  width: 100%;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 16px;
  line-height: 20px;
  background-color:whitesmoke;
}

#sport-dropdown {
  width: 100% ;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 16px;
  background-color:whitesmoke;
}

#up-information {
  width: 300px;
  font-size: 18px; 
}

#sb-button #updateButton {
  width: 100% ;
  font-weight: 600;
}

option {
  line-height: 20px;
}
 #updateProfile {
  width: 50%;
 }
 h1 {
  display: flex;
  justify-content: center;
 }

 #sb-button .updateButton {
  width: 100% !important;
 }