.sf-authForm {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin: auto;
  width: 400px;
  margin-bottom: 18px;

  /* flex-wrap: wrap; */
  flex-direction: column;
  border-radius: 10px;
}
h1 {
  font-size: 30px;
  padding: 10px;
  border-bottom: 1px solid gray;
}
h2 {
  font-size: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* .sf-email-container, .sf-favoriteSport-container, .sf-borough-containter, .sf-bio-container, .sf-name-container, .sf-password-container, .sf-username-container{
   height: 60px;
   width: 100%;
   margin-bottom: 0px;
} */

.sf-email-container,
.sf-bio-container,
.sf-name-container,
.sf-password-container,
.sf-username-container {
  height: 45px;
  width: 340px;
  border: 1px solid gray;
  padding-left: 5px;
  font-family: Jost, sans-serif;
  background-color: whitesmoke;
}

.sf-favoriteSport-container,
.sf-borough-containter {
  display: flex;
  justify-content: center;
  height: 49px;
  width: 349px;
  border: 1px solid gray;
  padding-left: 5px;
  font-family: Jost, sans-serif;
  background-color: whitesmoke;
  color: black;
}

.sf-auth-button {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .sf-auth-button{
    background-color: white;
    height: 45px;
    width: 100%;
    border: 1px solid gray;
    border-radius: 10px;
    justify-content: center;
}  */

#sb-button {
  width: 85%;
  height: 100%;
  justify-content: center;
  align-items: center;
}


.sf-name-container {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: whitesmoke;
}

.sf-favoriteSport-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #808080;
  background-color: whitesmoke;
}

.sf-borough-containter {
  color: #808080;
}

.sf-lg-email-container,
.sf-lg-password-container {
  height: 45px;
  width: 340px;
  border: 1px solid gray;
  padding-left: 5px;
  font-family: Jost, sans-serif;
}

.sf-lg-email-container {
  border-radius: 10px;
  background-color: whitesmoke;
}
.sf-lg-password-container {
  border-radius: 10px;
  background-color: whitesmoke;
}
