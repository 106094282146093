
#footer {
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  padding: 30px 20px;
  background-color: #f9e0c6;
  text-decoration: none !important;
}

#footer-links {
  text-decoration: none;
  color: black;
}

#footer-logoPic {
  max-height: 25px;
}

#footer-logo {
  flex: 1; 
}

#footer-middle {
  flex: 1; 
  text-align: center; 
}

#footer-end {
  flex: 1; 
  text-align: right; 
}