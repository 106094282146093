
.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  position: absolute;
  background-color:whitesmoke;
  border-radius: 16px;
  box-shadow: 5px 5px 15px rgb(143, 199, 227, 0.5);
}

.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(0,0,0, 0.5);
}
button {
  cursor: pointer;
}